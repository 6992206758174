import { css } from '@emotion/react';
import { memo } from 'react';
import { proxy } from 'valtio';
import useMedia from '~/hooks/useMedia';
import { fill_horizontal_all_center, fill_horizontal_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch';
import { store } from '~/pages/heineken_template/_private/store';
import { FuiUserAvatar } from '../../heineken_template/components/FuiUserAvatar';
import { Preset_Topbar } from '../../heineken_template/_preset/preset_Topbar';
import { OpkevinLogOutView } from './OpkevinLogOutView';
import { useDaddy960Trial } from './useDaddy960Tutorial';
import ChartingClientidSwitch from '~/modules/ChartingClientidSwitch/ChartingClientidSwitch';
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents';
// import { PocketBrokerEventLink } from '~/pages/daddy960_revive/component/PocketBrokerEventLink'
// import { PocketBrokerOpenAccountLogo } from '~/pages/daddy960_revive/component/PocketBrokerOpenAccountLogo'
export const daddy960_store = proxy({ userAvatar: new FuiUserAvatar() });
const TrialToolTip = memo(function TrialToolTip() {
    const { isPc } = useMedia();
    return (<div css={css `
        ${fill_horizontal_all_center};
        user-select: none;
        width: ${isPc ? '72' : '32'}px;
        font-size: ${isPc ? '14' : '9'}px;
        height: 32px;
        border-radius: 4px;
        background: linear-gradient(113deg, #ce7d00, #baa401);
      `}>
      {isPc ? '試用中' : '試用'}
    </div>);
});
export const Daddy960_TopBar = memo(function Daddy960_TopBar(props) {
    const trial = useDaddy960Trial(props.product);
    const clientid = props.clientId ?? [{ label: '', value: '' }];
    const agentName = fr_agents.agent;
    const productName = fr_agents.product;
    const proPermission = `${agentName}@${productName}_pro`;
    const defaultPermission = `${agentName}@${productName}`;
    const { isPc } = useMedia();
    return (<div css={css `
        ${fill_horizontal_cross_center};
        justify-content: end;
        background: linear-gradient(359deg, #25262b, #15161e);
      `}>
      <div>{trial.state.subscriptionsState}</div>
      {isPc && (<div css={css `
            ${fill_horizontal_all_center};
            justify-content: end;
            padding: 0px 16px;
            gap: 4px;
          `}>
          {trial.state.subscriptionsState === 'activation_code' && <TrialToolTip />}
          {props.clientId !== undefined && (<ChartingClientidSwitch.SelectSwitch data={clientid} permission1={proPermission} permission2={defaultPermission}/>)}

          {/* {productName.includes('revive') && <PocketBrokerOpenAccountLogo />} */}
          <ChartingServerSwitch charting={store.charting} css={css `
              width: 120px;
            `}/>
          <daddy960_store.userAvatar.AvatarModal cssset={css `
              width: 280px;
              height: 360px;
              transition: 0.3s;
            `}>
            <OpkevinLogOutView />
          </daddy960_store.userAvatar.AvatarModal>
        </div>)}

      {!isPc && (<Preset_Topbar showRightBurger={props.rightBerger ?? true} showLeftBurger={props.leftBurger ?? false} componentsInRight={<div css={css `
                ${fill_horizontal_all_center};
                justify-content: end;
              `}>
              {trial.state.subscriptionsState === 'trial' && <TrialToolTip />}
              {props.clientId !== undefined && (<ChartingClientidSwitch.IconSwitch data={clientid} permission1={proPermission} permission2={productName === 'goodway' ? defaultPermission : proPermission}/>)}
              <ChartingServerSwitch charting={store.charting} css={css `
                  width: 96px;
                `}/>
              <daddy960_store.userAvatar.AvatarModal cssset={css `
                  width: 280px;
                  height: 360px;
                `}>
                <OpkevinLogOutView />
              </daddy960_store.userAvatar.AvatarModal>
            </div>}/>)}
    </div>);
});
